/* Tailwind directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Fill up whole page */
.App {
  background-color: #010409;
  min-height: 100vh;
  font-family: 'Neuton', sans-serif;
  color: #fff;
  text-align: center;
}

/* Google Fonts */
.koulen-regular {
  font-family: 'Koulen', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.mulish-regular {
  font-family: 'Mulish', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.neuton-regular {
  font-family: 'Neuton', serif;
  font-weight: 400;
  font-style: normal;
}

/* Fixed header, doesn't move when scrolling down */
.header {
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1000;

  flex-direction: row;
  align-items: center;
  font-size: calc(10px + 2vmin);
  background-color: #010409;
}

.selected,
.theme {
  color: #ce6240;
}

.hollow-theme {
  color: black;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #ce6240;
}

.ant-drawer-header {
  border-bottom: 0px !important;
}
